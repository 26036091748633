/* QUill */
.quill{
    display: flex !important;
    flex-direction: column-reverse !important;
}
.quill .ql-toolbar{
    border: none !important;
    border-top: 1px solid var(--chakra-colors-gray-100) !important;
    padding: 0.5rem !important;

}
.quill .ql-editor{
    padding: 0.5rem 1rem !important;
    font-size: 16px;
    min-height: 6rem;
}
.quill .ql-container{
    border: none !important;
}

.table-input{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0;
    
}

.table-input input, .table-input button{
    border-radius: 0px !important;   
}

.table-input label{
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    padding: 8px;
    margin: 0px;
    min-height: 84px;
    border: 1px solid #E2E8F0;
}

.hide-label{
    padding: 0 !important;
}

.h-adjust{
    height: 2.5rem !important;
}

.ttv-header .radio-wrapper {
    border: none !important;
}
.ttv-header .radio-wrapper label{
    padding: 4px !important;
}
.ttv-header label{
    padding: 8px 12px 0px;
    margin: 0px !important;
}

.total-header {
    display: flex;
    align-items: flex-end;
}

.table-radio{
    border: 1px solid #E2E8F0;
    max-height: 2.5rem;
}
.hardware label span{
    font-size: 14px !important;
    overflow: hidden !important;
    height: 19px;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
}

.hardware label span:hover{
    white-space: nowrap;
}

/* .radio-wrapper .chakra-radio__control{
    display: none;
}

.radio-wrapper span{
    margin: 0;
}

.radio-wrapper{
    padding: 4px 12px !important;
} */

/* [class*="-delete"] {
    color: var(--chakra-colors-red-500) !important;
} */

@media screen and (min-width: 96rem) {
    .one-side-margin{
        margin-left: calc(50vw - 48rem);
    }
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropArea {
    position: relative;
}

.dropArea::before {
    content: '';
    position: absolute;
    z-index: 300;
    width: 100%;
    height: 100%;
    background-color: #b8b8b881;
}


@media screen and (max-width: 992px) {
    .cbx-prompt-modal{
        margin-bottom: 0px !important;
        margin-top: auto !important;
        border-radius: 0px !important;
    }
    .cbx-create-modal{
        margin-bottom: 0px !important;
        margin-top: auto !important;
        border-radius: 0px !important;
    }
}

.sizes-ref-item{
    width: auto !important;
    margin-right: 0.4rem !important;
}


/* .cbx-header-docs .cbx-wrapper{
    margin: 0px auto !important;
    max-width: 820px !important;
} */
/* 
.cbx-tab-content{
    background: white !important;
}

.chakra-ui-dark .cbx-tab-content{
    background: black !important;
}

.cbx-tab-content .cbx-field-title{
    max-width: 830px;
    margin: 0 auto;
    
}

.cbx-tab-content .cbx-field-title input{
    border: none !important;
    font-size: 2rem;
    font-weight: 700;
    padding: 0.4rem 0.8rem;
    height: auto !important;
}

.cbx-tab-content .cbx-field-title input:focus{
    border: 0px !important;
    outline: none !important;
    box-shadow: none !important;
} */